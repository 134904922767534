/* -------------- Form Styles ---------------- */
.dbx-2.form .main-error-message {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  padding: 16px 0;
}

.dbx-2.form .main-error-message span {
  margin-right: 16px;
  background: #d11212;
  text-align: center;
  font-size: 12px;
  color: #fff;
  height: 35px;
  width: 35px;
  line-height: 35px;
  border-radius: 50%;
  flex-shrink: 0;
}

.dbx-2.form .main-error-message p {
  color: #d11212;
  font-size: 13px;
}

.dbx-2.form .group {
  margin-bottom: 16px;
}

.dbx-2.input-container {
  position: relative;
}

.dbx-2.input-container i,
.dbx-2.select i {
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  font-size: 11px;
  color: #8c97a9;
}

label.dbx-2 {
  display: inline-block;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 8px;
  color: #212121;
}

input.dbx-2[type='text'],
input.dbx-2[type='password'] {
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  display: block;
  width: 100%;
  background: #fafafa;
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  padding: 12px;
  font-size: 14px;

  transition: box-shadow 0.2s;
}

input.dbx-2[type='text'].error,
input.dbx-2[type='password'].error {
  border-color: #d11212;
}

input.dbx-2[type='text']:focus:not(.error),
input.dbx-2[type='password']:focus:not(.error) {
  border: 1px solid #c7e3ee;
}

input.dbx-2[type='text']:focus,
input.dbx-2[type='password']:focus {
  border: 1px solid #00aeef;
}

.dbx-2.radio,
.dbx-2.checkbox {
  margin-bottom: 4px;
  position: relative;
}

.dbx-2.radio label,
.dbx-2.checkbox label {
  position: relative;
  display: inline-block;
  color: #646464;
  font-size: 13px;
  padding: 2px 24px 2px 24px;
  cursor: pointer;
}

.dbx-2.radio label::before,
.dbx-2.checkbox label::before {
  display: block;
  content: '';
  background: transparent;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  width: 16px;
  height: 16px;
  border: 2px solid #dde3e7;
}

.dbx-2.radio label::before {
  border-radius: 50%;
}

.dbx-2.radio label::after,
.dbx-2.checkbox label::after {
  display: block;
  content: '';
  background: transparent;
  position: absolute;
  left: 4px;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  width: 8px;
  height: 8px;
  background: transparent;
}

.dbx-2.radio label::after {
  border-radius: 50%;
}

.dbx-2.radio input:checked + label::after,
.dbx-2.checkbox input:checked + label::after {
  background: #0d7ad7;
}

.dbx-2.radio input,
.dbx-2.checkbox input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 0;
  height: 0;
}

/* ------------------ Select Styles ---------------------- */
.dbx-2.select {
  position: relative;
}

.dbx-2.select::after {
  display: block;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-size: 12px;
  content: '\f078';
  color: #5d6778;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  pointer-events: none;
}

.dbx-2.select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;

  cursor: pointer;
  display: block;
  width: 100%;
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid #ddd;
  background: #fafafa;
  color: #5d6778;
  font-size: 14px;
  font-weight: 600;
  outline: none;
}

/* ------------- Button Styles --------------- */
.dbx-2.button {
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  border: none;

  background: none;
  font-size: 12px;
  /* font-weight: 600; */
  padding: 12px 16px;
  border-radius: 4px;
  background: #eaeaea;
}

.dbx-2.button.blue {
  background: #1f4f93;
  color: #fff;
}

.dbx-2.button.green {
  background: #11a702;
  color: #fff;
}

.dbx-2.button.orange {
  background: #f47200;
  color: #fff;
}

.dbx-2.button.text {
  background: transparent;
  padding: 8px 2px;
}

.dbx-2.button.text.blue {
  color: #0572d4;
}

.dbx-2.button.rounded {
  border-radius: 32px;
}

.dbx-2.button.block {
  width: 100%;
}

.dbx-2.button i {
  margin-left: 4px;
  margin-right: 4px;
}

.dbx-2.button.right {
  display: block;
  margin-left: auto;
  max-width: max-content;
}

.dbx-2.button.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: max-content;
}

.dbx-2.button.loading {
  position: relative;
  color: transparent;
}

@keyframes spin {
  from {
    transform: rotate(0deg) translateX(-50%) translateY(-50%);
  }
  to {
    transform: rotate(360deg) translateX(-50%) translateY(-50%);
  }
}

.dbx-2.button.loading::after {
  display: block;
  color: #fff;

  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  content: '\f1ce';
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: 0px 0px;
  animation: spin 0.8s linear infinite;
}

/* --------------- Transactions Table -------------- */
.dbx-2-transactions-table {
  overflow-x: auto;
  margin-bottom: 32px;
}

.dbx-2-transactions-table table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #a5c9ff;
  border-radius: 8px;
}

.dbx-2-transactions-table table tr {
  background: #fff;
}

.dbx-2-transactions-table table tbody tr:hover {
  background: #f4f7fa;
}

.dbx-2-transactions-table table th,
.dbx-2-transactions-table table td {
  padding: 16px;
  text-align: left;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  color: #174a84;
}

.dbx-2-transactions-table table thead th {
  font-size: 13px;
  font-weight: 400;
  cursor: pointer;
}

.dbx-2-transactions-table table thead th:hover {
  background: #f4f7fa;
}

.dbx-2-transactions-table table thead th span {
  display: inline-block;
  margin-left: 4px;
  color: #aaffbe;
}

.dbx-2-transactions-table table td {
  font-size: 13px;
}

.dbx-2-transactions-table table tbody td {
  border-top: 1px solid #a5c9ff;
}

.dbx-2-transactions-table table thead th:first-of-type {
  border-top-left-radius: 8px;
}

.dbx-2-transactions-table table thead th:last-of-type {
  border-top-right-radius: 8px;
}

.dbx-2-transactions-table table tbody tr:last-of-type td:first-of-type {
  border-bottom-left-radius: 8px;
}

.dbx-2-transactions-table table tbody tr:last-of-type td:last-of-type {
  border-bottom-right-radius: 8px;
}

.dbx-2-transactions-table table tbody td.amount span {
  color: #23134f;
  background: #ffefca;
  padding: 4px 8px;
  border-radius: 4px;
  display: inline-block;
  min-width: 120px;
  text-align: center;
  font-weight: 500;
}

.dbx-2-transactions-table table tbody td.status {
  font-weight: 500;
}

.dbx-2-transactions-table table tbody td.status.SUCCESSFUL {
  color: #038514;
}

.dbx-2-transactions-table table tbody td.status.FAILED {
  color: #ee0404;
}

.dbx-2-transactions-table table tbody td.status.PENDING {
  color: #e6ee04;
}

.dbx-2-transactions-table table tbody td.action {
  position: relative;
}

.dbx-2-transactions-table table tbody td.action .circles {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);

  cursor: pointer;
  width: max-content;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2px 4px;
  padding: 16px;
}

.dbx-2-transactions-table table tbody td.action .circles .circle {
  width: 4px;
  height: 4px;
  background: #a5c9ff;
  border-radius: 50%;
}

/* ---------------- Admin Pages -------------- */
.admin-heading {
  margin-bottom: 32px;
}

.admin-heading h2 {
  font-size: 32px;
  font-weight: 400;
  color: #11193c;
  margin-bottom: 4px;
}

.admin-heading p {
  font-size: 14px;
  color: #8990ad;
}

.admin-header-aware {
  padding-top: 70px;
}

.admin-header {
  padding: 16px;
  display: flex;
  align-items: center;
  background: #fff;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.08);

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.admin-header .nav-trigger {
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  border: none;
  background: transparent;
  padding: 10px;
  background: rgba(56, 138, 247, 0.1);
  border-radius: 8px;
}

.admin-header .nav-trigger .circles {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2px;
}

.admin-header .nav-trigger .circles .circle {
  width: 8px;
  height: 8px;
  background: #1f4f93;
  border-radius: 50%;
}

.admin-header .nav-trigger .circles .circle:nth-child(1) {
  background: #7bdefb;
}

.admin-header .right {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.admin-header img.profile-picture {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  margin-right: 8px;

  box-shadow: 0 4px 26px rgba(0, 0, 0, 0.3);
}

.admin-header .name {
  font-size: 13px;
  color: #8990ad;
}

.admin-header .user-dropdown {
  margin-left: 16px;
  position: relative;
}

.admin-header .user-dropdown .circles {
  display: grid;
  grid-template-columns: 1fr;
  gap: 3px;
  cursor: pointer;
  padding: 8px;
}

.admin-header .user-dropdown .circles .circle {
  width: 5px;
  height: 5px;
  background: #8990ad;
  border-radius: 50%;
}

.admin-header .user-dropdown .menu {
  background: #fff;
  position: absolute;
  top: calc(100% + 16px);
  right: 0;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.08);
  padding: 12px 16px 4px;
  border-radius: 4px;

  display: none;
}

.admin-header .user-dropdown.menu-visible .menu {
  display: block;
}

.admin-header .user-dropdown .menu .email {
  padding: 8px 0;
  color: #8990ad;
  font-size: 14px;
  border-bottom: 1px solid #ebecf1;
}

.admin-header .user-dropdown .menu ul li {
  padding: 8px 0;
}

.admin-header .user-dropdown .menu ul li:not(:last-of-type) {
  border-bottom: 1px solid #ebecf1;
}

.admin-header .user-dropdown .menu ul li button {
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  border: none;
  background: transparent;

  display: block;
  margin: 0 auto;
  color: #8990ad;
  font-size: 13px;
  padding: 4px;
}

.admin-header .user-dropdown .menu ul li button i {
  margin-left: 8px;
}

.admin-header .user-dropdown .menu ul li button.view-profile {
  border: 1px solid #1f4f93;
  color: #1f4f93;
  border-radius: 4px;
  padding: 8px;
}

.admin-header nav.admin-nav {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: #fff;
  box-shadow: 0 32px 32px rgba(0, 0, 0, 0.15);
}

.admin-header.admin-nav-visible nav.admin-nav {
  display: block;
}

.admin-header nav.admin-nav ul li:not(:last-of-type) {
  border-bottom: 1px solid rgba(56, 138, 247, 0.1);
}

.admin-header nav.admin-nav ul li a {
  display: flex;
  align-items: center;
  padding: 20px 16px;
  font-size: 16px;
  color: #8990ad;
}

.admin-header nav.admin-nav ul li.go-live {
  padding: 16px;
}

.admin-header nav.admin-nav ul li.go-live a {
  color: #fff;
  background: #1f4f93;
  justify-content: center;
  border-radius: 4px;
}

.admin-header nav.admin-nav ul li.go-live a img {
  margin-right: 8px;
}

.admin-header nav.admin-nav ul li a.active {
  color: #1f4f93;
}

.admin-header nav.admin-nav ul li a img {
  width: 16px;
  margin-right: 16px;
}

.admin-header nav.admin-nav ul li:not(.button) a img {
  opacity: 0.3;
}

.admin-header nav.admin-nav ul li:not(.button) a.active img {
  opacity: 1;
}

@media (min-width: 960px) {
  .admin-header-aware {
    margin-left: 160px;
  }

  .admin-header {
    padding: 16px;
    box-shadow: none;

    position: fixed;
    top: 0;
    left: calc(160px + 64px);
    right: 64px;
    border-bottom: 1px solid #dae9ff;
  }

  .admin-header img.profile-picture {
    width: 45px;
    height: 45px;
    margin-right: 16px;
  }

  .admin-header .name {
    font-size: 16px;
  }

  .admin-header .nav-trigger {
    display: none;
  }

  .admin-header nav.admin-nav {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 160px;
    box-shadow: 8px 0 32px rgba(0, 0, 0, 0.08);
    overflow-y: auto;
    padding-top: 60px;
  }

  .admin-header nav.admin-nav ul {
    padding: 16px;
  }

  .admin-header nav.admin-nav ul li:nth-child(n) {
    border-bottom: none;
  }

  .admin-header nav.admin-nav ul li:not(.button) a {
    flex-direction: column;
    text-align: center;
    border-radius: 8px;
  }

  .admin-header nav.admin-nav ul li a.active {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.12);
  }

  .admin-header nav.admin-nav ul li:not(.button) a img {
    width: 32px;
    margin-right: 0;
    margin-bottom: 12px;
  }

  .admin-header nav.admin-nav ul li.go-live {
    margin-top: 64px;
    padding: 0;
  }

  .admin-header nav.admin-nav ul li.go-live a {
    padding: 12px 16px;
  }
}

/* --------------- Main Styles -------------- */

.HomePage .header {
  padding: 16px;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 32px;
}

.HomePage .header .big-circle {
  height: 300px;
  width: 300px;
  border-radius: 50%;
  background: rgba(56, 138, 247, 0.04);

  position: absolute;
  top: -150px;
  left: -150px;
}

.HomePage .header nav.mobile {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: #fff;
  box-shadow: 0 32px 32px rgba(0, 0, 0, 0.15);
}

.HomePage .header.mobile-nav-visible nav.mobile {
  display: block;
}

.HomePage .header nav.mobile ul li:not(:last-of-type) a {
  border-bottom: 1px solid rgba(56, 138, 247, 0.1);
}

.HomePage .header nav.mobile ul li a {
  display: block;
  padding: 16px;
  font-size: 16px;
  /* color: #1f4f93; */
}

.HomePage .header .site-logo {
  z-index: 10;
  margin-right: auto;
  flex-shrink: 0;
}

.HomePage .header .site-logo img {
  display: block;
  width: 96px;
}

.HomePage .header nav.main {
  display: none;
}

.HomePage .header .nav-trigger {
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  border: none;
  background: transparent;
  padding: 10px;
  margin-left: 16px;
}

.HomePage .header .nav-trigger {
  background: rgba(56, 138, 247, 0.1);
  border-radius: 8px;
}

.HomePage .header .nav-trigger .circles {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2px;
}

.HomePage .header .nav-trigger .circles .circle {
  width: 8px;
  height: 8px;
  background: #1f4f93;
  border-radius: 50%;
}

.HomePage .header .nav-trigger .circles .circle:nth-child(1) {
  background: #7bdefb;
}

.HomePage .header .get-started {
  color: #1f4f93;
  font-weight: 500;
  font-size: 12px;
  line-height: 0;
  border: 1px dotted #1f4f93;
  border-radius: 8px;
  padding: 14px 16px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.HomePage .header .get-started .circles {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1px;
  margin-right: 4px;
}

.HomePage .header .get-started .circles .circle {
  width: 4px;
  height: 4px;
  background: #1f4f93;
  border-radius: 50%;
}

.HomePage .header .get-started .circles .circle:nth-child(1) {
  background: #7bdefb;
}

.HomePage .banner {
  padding: 16px;
  margin-bottom: 32px;
}

.HomePage .banner .illustration {
  /* margin-bottom: 32px; */
  max-width: 480px;
}

.HomePage .banner h2 {
  font-weight: 400;
  font-size: 28px;
  line-height: 32px;
  margin-bottom: 8px;
}

.HomePage .banner h2 span {
  display: inline-block;
  font-weight: 500;
  font-size: 36px;
  margin-bottom: 12px;
}

.HomePage .banner p {
  color: #b4b4b4;
  font-size: 16px;
  line-height: 20px;
  max-width: 480px;
}

.HomePage .banner .buttons {
  margin-top: 16px;
  display: flex;
}

.HomePage .banner .buttons a {
  color: #1f4f93;
  font-weight: 500;
  font-size: 12px;
  line-height: 0;
  border: 1px dotted #1f4f93;
  border-radius: 8px;
  padding: 16px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.HomePage .banner .buttons a:not(:last-of-type) {
  margin-right: 8px;
}

.HomePage .banner .buttons a.get-started {
  color: #fff;
  background: #1f4f93;
  border: none;
}

.HomePage .banner .buttons .circles {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1px;
  margin-right: 4px;
}

.HomePage .banner .buttons .circles .circle {
  width: 4px;
  height: 4px;
  background: #1f4f93;
  border-radius: 50%;
}

.HomePage .banner .buttons .circles .circle:nth-child(1) {
  background: #7bdefb;
}

.HomePage .banner .buttons a.get-started .circles .circle {
  background: #fff;
}

.HomePage .banner .buttons a.get-started .circles .circle:nth-child(1) {
  background: #7bdefb;
}

.HomePage .services {
  padding: 16px;
  max-width: 1140px;
  margin: 0 auto;
}

.HomePage .services h2 {
  text-align: center;
  font-weight: 500;
  font-size: 32px;
  margin-bottom: 32px;
}

.HomePage .services .service {
  margin-bottom: 24px;
}

.HomePage .services .service .name {
  font-size: 20px;
  margin-bottom: 16px;
}

.HomePage .services .service .main {
  padding: 24px;
  background: #fff;
  border: 1px solid #1f4f93;
  border-radius: 8px;
  display: flex;
  align-items: center;
}

.HomePage .services .service .main img {
  width: 48px;
  margin-right: 24px;
  flex-shrink: 0;
}

.HomePage .services .service .main p {
  color: #b4b4b4;
  font-size: 15px;
  line-height: 20px;
}

@media (min-width: 540px) {
  .HomePage .header {
    padding: 32px;
  }

  .HomePage .header .site-logo img {
    width: 160px;
  }

  .HomePage .banner {
    padding: 16px 32px;
  }

  .HomePage .banner h2 {
    font-size: 36px;
    line-height: 40px;
  }

  .HomePage .banner h2 span {
    font-size: 48px;
  }

  .HomePage .services {
    padding: 32px;
  }
}

@media (min-width: 720px) {
  .HomePage .header .site-logo {
    margin-right: 0;
  }

  .HomePage .header.mobile-nav-visible nav.mobile {
    display: none;
  }

  .HomePage .header nav.main {
    display: block;
    margin-left: 32px;
    margin-right: auto;
  }

  .HomePage .header nav.main ul {
    display: flex;
    align-items: center;
  }

  .HomePage .header nav.main ul li {
    margin-right: 16px;
  }

  .HomePage .header nav.main ul li a {
    display: block;
    padding: 8px;
    font-size: 14px;
    color: #bfc5d2;
  }

  .HomePage .header .nav-trigger {
    display: none;
  }

  .HomePage .services ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 32px 10%;
  }

  .HomePage .services h2 {
    font-size: 48px;
    margin-bottom: 56px;
  }

  .HomePage .services .service {
    margin-bottom: 0;
  }

  .HomePage .services .service .name {
    font-size: 24px;
    /* margin-bottom: 16px; */
  }

  .HomePage .services .service .main {
    padding: 32px;
  }
}

@media (min-width: 840px) {
  .HomePage .banner {
    display: flex;
  }

  .HomePage .banner .illustration {
    order: 1;
    flex-shrink: 0;
    max-width: none;
    width: 50%;
    margin-left: 32px;
  }

  .HomePage .banner .main {
    padding-top: 64px;
    padding-bottom: 64px;
  }
}

@media (min-width: 960px) {
  .HomePage .header nav.main {
    margin-left: 64px;
  }

  .HomePage .banner {
    max-width: 1320px;
    margin: 0 auto;
  }

  .HomePage .banner h2 {
    font-size: 48px;
    line-height: 50px;
  }

  .HomePage .banner h2 span {
    font-size: 64px;
    margin-bottom: 20px;
  }

  .HomePage .banner .buttons a {
    font-size: 16px;
    padding: 20px;
  }

  .HomePage .banner .buttons .circles {
    margin-right: 8px;
  }

  .HomePage .banner .buttons .circles .circle {
    width: 8px;
    height: 8px;
  }
}

/* ----------------Login Page---------------- */

.Page.auth-page .wrapper {
  max-width: 480px;
  margin: 0 auto;
  padding-bottom: 200px;
}

.Page.auth-page .top .logo {
  padding-top: 16px;
}

.Page.auth-page .top .logo {
  display: block;
  margin: 0 auto;
  width: 96px;
  margin-bottom: 8px;
}

.Page.auth-page .top .links {
  display: flex;
  align-items: center;
}

.Page.auth-page .top .links a {
  display: block;
  flex: 1;
  text-align: center;
  color: #d8d8d8;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 2px solid #d8d8d8;
  padding: 12px;
}

.Page.auth-page .top .links a.active {
  color: #1f4f93;
  border-color: #1f4f93;
}

.Page.auth-page .main {
  padding: 0 16px;
}

.Page.auth-page img.illustration {
  max-width: 120px;
  display: block;
  margin: 32px auto;
}

.Page.auth-page .main .heading-container {
  margin-bottom: 24px;
}

.Page.auth-page .main .heading-container h2 {
  font-size: 20px;
}

.Page.auth-page .completed {
  padding: 48px 16px;
  text-align: center;
}

.Page.auth-page .completed h2 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 8px;
}

.Page.auth-page .completed p {
  font-size: 14px;
  color: #5d6778;
}

.Page.auth-page .completed a {
  display: block;
  width: max-content;
  margin: 16px auto;
}

.SignUpPage .heading-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.SignUpPage .heading-container button {
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  border: none;
  color: #1f4f93;
  background: #fff;

  font-size: 13px;
  font-weight: 500;
  padding: 4px 8px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
}

.SignUpPage .heading-container button span {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
  border: 2px solid #1f4f93;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

button.google-auth {
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  border: none;

  position: relative;
  width: 100%;
  justify-content: center;
  background: #fff;
  color: #212121;
  padding: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  font-size: 13px;
  font-weight: 600;
  border-radius: 8px;
}

button.google-auth img {
  width: 16px;
  margin-right: 8px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 16px;
}

.Page.auth-page .main .divider {
  text-align: center;
  position: relative;
  margin: 12px 0;
}

.Page.auth-page .main .divider::after {
  display: block;
  content: '';
  position: absolute;
  height: 1px;
  width: 100%;
  left: 0;
  background: #e0e0e0;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}

.Page.auth-page .main .divider span {
  display: inline-block;
  color: #e0e0e0;
  padding: 2px 16px;
  background: #fff;
  font-size: 20px;
}

.Page.auth-page .main .bottom {
  margin-top: 32px;
}

.Page.auth-page .main .bottom button {
  display: block;
  padding: 16px;
  width: 100%;
}

.Page.auth-page .main .bottom a.forgot-password {
  display: block;
  width: max-content;
  margin: 8px auto 0;
  padding: 8px;
  color: #1f4f93;
  font-size: 14px;
  font-weight: 500;
}

.Page.auth-page .group.country .input-container {
  display: flex;
  align-items: center;
}

.Page.auth-page .group.country .input-container .select {
  flex: 1;
  margin-right: 12px;
}

.Page.auth-page .group.country .input-container img {
  width: 60px;
  border-radius: 4px;
}

@media (min-width: 960px) and (min-height: 700px) {
  .Page.auth-page .big-circle {
    height: 350px;
    width: 350px;
    border-radius: 50%;
    background: rgba(56, 138, 247, 0.04);

    position: fixed;
    top: -150px;
    left: -150px;
  }

  .Page.auth-page .circles {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 8px;
    position: absolute;
  }

  .Page.auth-page .circles .circle {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: rgba(65, 72, 90, 0.1);
  }

  .Page.auth-page .circles-top {
    top: 0;
    right: 10%;
    transform: translateY(-110%);
  }

  .Page.auth-page .circles-bottom {
    bottom: 0;
    left: 20%;
    transform: translateY(110%);
  }

  .Page.auth-page .wrapper {
    position: fixed;
    width: 100%;
    max-width: 920px;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

    padding: 72px 64px 72px 100px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.08);
    background: #fff;
  }

  .Page.auth-page .top .logo {
    padding: 0;
    margin: 0;
    width: 96px;

    position: fixed;
    top: 24px;
    left: 24px;
  }

  .Page.auth-page .top .links {
    display: block;
    position: fixed;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .Page.auth-page .top .links a {
    flex: none;
    color: #d8d8d8;
    font-size: 16px;
    border-bottom: none;
    border-left: 3px solid #d8d8d8;
    margin-bottom: 12px;
    padding: 32px 16px;
  }

  .Page.auth-page .top .links a.active {
    color: #1f4f93;
    border-color: #1f4f93;
  }

  .Page.auth-page .main {
    display: flex;
    align-items: center;
  }

  .Page.auth-page .main img.illustration {
    max-width: 100%;
    width: 45%;
    flex-shrink: 0;
    margin: 0;
    margin-right: 42px;
  }

  .Page.auth-page .main .main-content {
    flex: 1;
  }
}

/* ----------------Transactions Page---------------- */
.TransactionsPage .main {
  padding: 16px;
}

@media (min-width: 960px) {
  .TransactionsPage .main {
    padding: 64px;
  }
}

/* ----------------Dashboard Page---------------- */
.DashboardPage .main {
  padding: 16px;
}

.DashboardPage .summary {
  margin-bottom: 64px;
}

.DashboardPage .summary ul {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 32px;
}

.DashboardPage .summary .label {
  color: #8990ad;
  font-size: 16px;
  letter-spacing: 0.6px;
  margin-bottom: 8px;
}

.DashboardPage .summary .amount-container {
  display: flex;
  align-items: center;
}

.DashboardPage .summary .amount {
  color: #11193c;
  font-size: 16px;
  font-weight: 600;
}

.DashboardPage .summary .amount-container.up {
  color: #00e38c;
}

.DashboardPage .summary .amount-container.down {
  color: #ff4b75;
}

.DashboardPage .summary .amount-container i {
  margin: 0 8px;
}

.DashboardPage .progress {
  padding: 16px 0;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  margin-bottom: 64px;
}

.DashboardPage .progress ul {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.DashboardPage .progress ul li {
  padding: 16px 0;
}

.DashboardPage .progress .month {
  color: #495172;
  text-align: center;
  margin-bottom: 16px;
}

.DashboardPage .progress .delta-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.DashboardPage .progress .delta {
  color: #11193c;
  font-size: 32px;
  font-weight: 600;
}

.DashboardPage .progress .delta-container.up {
  color: #00e38c;
}

.DashboardPage .progress .delta-container.down {
  color: #ff4b75;
}

.DashboardPage .progress .delta-container i {
  margin: 0 8px;
}

.DashboardPage .your-transactions .dbx-2-transactions-table {
  max-width: 840px;
}

.DashboardPage .your-transactions h2 {
  color: #11193c;
  font-size: 20px;
  margin-bottom: 16px;
}

@media (min-width: 720px) {
  .DashboardPage .summary ul {
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 0;
  }

  .DashboardPage .summary ul li {
    display: flex;
    justify-content: center;
  }

  .DashboardPage .summary ul li .wrapper {
    /* padding: 0 32px; */
  }

  .DashboardPage .summary ul li:first-of-type {
    padding-left: 0;
    justify-content: flex-start;
  }

  .DashboardPage .summary ul li:last-of-type {
    padding-right: 0;
    justify-content: flex-end;
  }

  .DashboardPage .summary ul li:last-of-type .label {
    text-align: right;
  }

  .DashboardPage .summary ul li:last-of-type .amount-container {
    justify-content: flex-end;
  }

  .DashboardPage .summary ul li:not(:last-of-type) {
    border-right: 1px solid #c5c9d7;
  }

  .DashboardPage .summary .label {
    /* font-size: 16px; */
    margin-bottom: 16px;
  }

  .DashboardPage .summary .amount {
    font-size: 24px;
  }

  .DashboardPage .progress ul {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (min-width: 960px) {
  .DashboardPage .main {
    padding: 64px;
  }
}

@media (min-width: 1140px) {
  .DashboardPage .progress ul {
    grid-template-columns: repeat(6, 1fr);
  }
}
