/* ------------- Loading Page ---------- */
#loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

#loading span {
  margin-bottom: 8px;
}

#loading span i {
  font-size: 32px;
}

.dbx-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
}

.dbx-loading span {
  margin-bottom: 8px;
}

.dbx-loading span i {
  font-size: 32px;
}

/* ------------- Button Styles --------------- */
.dbx.button {
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  border: none;

  background: none;
  font-size: 12px;
  font-weight: 600;
  padding: 12px 16px;
  border-radius: 4px;
  background: #eaeaea;
}

.dbx.button.blue {
  background: #0572d4;
  color: #fff;
}

.dbx.button.green {
  background: #11a702;
  color: #fff;
}

.dbx.button.orange {
  background: #f47200;
  color: #fff;
}

.dbx.button.text {
  background: transparent;
  padding: 8px 2px;
}

.dbx.button.text.blue {
  color: #0572d4;
}

.dbx.button.rounded {
  border-radius: 32px;
}

.dbx.button i {
  margin-left: 4px;
  margin-right: 4px;
}

.dbx.button.right {
  display: block;
  margin-left: auto;
  max-width: max-content;
}

.dbx.button.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: max-content;
}

.dbx.button.loading {
  position: relative;
  color: transparent;
}

@keyframes spin {
  from {
    transform: rotate(0deg) translateX(-50%) translateY(-50%);
  }
  to {
    transform: rotate(360deg) translateX(-50%) translateY(-50%);
  }
}

.dbx.button.loading::after {
  display: block;
  color: #fff;

  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  content: '\f1ce';
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: 0px 0px;
  animation: spin 0.8s linear infinite;
}

/* -------------- Form Styles ---------------- */

.dbx.form .main-error-message {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  padding: 16px;
}

.dbx.form .main-error-message span {
  margin-right: 16px;
  background: #d11212;
  text-align: center;
  font-size: 12px;
  color: #fff;
  height: 35px;
  width: 35px;
  line-height: 35px;
  border-radius: 50%;
  flex-shrink: 0;
}

.dbx.form .main-error-message p {
  color: #d11212;
  font-size: 13px;
}
.dbx.form .group {
  margin-bottom: 16px;
}

.dbx.input-container {
  position: relative;
}

.dbx.input-container i,
.dbx.select i {
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  font-size: 11px;
  color: #8c97a9;
}

label.dbx {
  display: inline-block;
  padding-left: 14px;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 8px;
  color: #8c97a9;
}

input.dbx[type='text'],
input.dbx[type='password'] {
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  display: block;
  width: 100%;
  background: #fff;
  border: 1px solid #dddddd;
  border-radius: 32px;
  padding: 12px 12px 12px 40px;
  font-size: 14px;
  font-weight: 600;
  color: #5d6778;

  transition: box-shadow 0.2s;
}

input.dbx[type='text'].error,
input.dbx[type='password'].error {
  border-color: #d11212;
}

input.dbx[type='text']:focus:not(.error),
input.dbx[type='password']:focus:not(.error) {
  border: 1px solid #c7e3ee;
}

input.dbx[type='text']:focus,
input.dbx[type='password']:focus {
  border: 1px solid #00aeef;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.08);
}

/* ------------------ Select Styles ---------------------- */
.dbx.select {
  position: relative;
}

.dbx.select::after {
  display: block;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-size: 12px;
  content: '\f078';
  color: #5d6778;
  position: absolute;
  top: 50%;
  right: 22px;
  transform: translateY(-50%);
  pointer-events: none;
}

.dbx.select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  cursor: pointer;
  display: block;
  width: 100%;
  padding: 12px 12px 12px 40px;
  border-radius: 32px;
  border: 1px solid #ddd;
  color: #5d6778;
  font-size: 14px;
  font-weight: 600;
  background: #fff;
  outline: none;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.08);
}

.sidebar {
  position: fixed;
  top: 70px;
  left: 0;
  width: 300px;
  height: calc(100% - 70px);
  background: #fff;
  border-right: 1px solid #e7e9ed;
  padding: 16px;
  overflow-y: auto;

  transform: translateX(-100%);
}

.sidebar ul li.section-title {
  margin-bottom: 4px;
}

.sidebar ul li.section-title:not(:first-of-type) {
  margin-top: 16px;
}

.sidebar ul li:not(.section-title) {
  margin-left: 44px;
  position: relative;
}

.sidebar ul li:not(.section-title)::after {
  display: block;
  content: '';
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  background: #eaf1f7;
}

.sidebar ul li:not(.section-title):hover::after {
  background: #0572d4;
}

.sidebar ul li.section-title a {
  display: flex;
  align-items: center;
  padding: 4px;
  font-size: 13px;
  font-size: 14px;
  font-weight: 600;
  color: #0572d4;
}

.sidebar ul li.section-title a span {
  color: #0572d4;
  background: #eaf1f7;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  text-align: center;
  margin-right: 10px;
  font-size: 10px;
}

.sidebar ul li:not(.section-title) a {
  display: block;
  padding: 10px 4px 10px 16px;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #8c97a9;
}

.sidebar ul li:not(.section-title) a:hover {
  color: #0572d4;
}

@media (min-width: 840px) {
  .sidebar {
    transform: translateX(0);
  }

  .sidebar-aware {
    margin-left: 300px;
  }
}

.heading-1 {
  color: #5d6778;
  font-size: 20px;
  font-weight: 600;
  padding: 4px 0;
  border-bottom: 1px solid #e7e9ed;
  margin-bottom: 16px;
}

.heading-2 {
  color: #5d6778;
  font-size: 16px;
  font-weight: 600;
  padding: 4px 0;
  border-bottom: 1px solid #e7e9ed;
  margin-bottom: 16px;
}
