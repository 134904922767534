@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap');

*,
*::before,
*::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  cursor: pointer;
}

button:focus {
  outline: none;
}

body {
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
    'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 100%;
  font-weight: 500;
  letter-spacing: -0.6px;
  color: #2c3f4c;
}

.alt-font-family {
  font-family: 'Rubik', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
    'Helvetica Neue', sans-serif;
  font-size: 100%;
  font-weight: initial;
  letter-spacing: -0.6px;
  color: #2c2c2c;
}

body ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 4px;
  height: 4px;
}

body ::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 0px;
}

body ::-webkit-scrollbar-thumb {
  cursor: pointer;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.25);
  -webkit-transition: color 0.2s ease;
  transition: color 0.2s ease;
}

body ::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(0, 0, 0, 0.15);
}

body ::-webkit-scrollbar-thumb:hover {
  background: rgba(128, 135, 139, 0.8);
}

.disable-selection {
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer */
  -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
  -webkit-user-select: none; /* Chrome, Safari, and Opera */
  -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
}

.fa-spin {
  animation-duration: 0.8s;
}

.header-aware {
  padding-top: 70px;
}

.empty-list-message {
  text-align: center;
}

header {
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  border-bottom: 1px solid #e7e9ed;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.08);
}

header > .wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  height: 70px;
  /* max-width: 1680px;
  margin: 0 auto; */
}

header .logo {
  flex-shrink: 0;
}

header .logo img {
  width: 90px;
}

header .right {
  display: flex;
  align-items: center;
}

header .right .main-nav {
  display: none;
}

header .right .mobile-nav {
  /* display: none; */
  background: #fff;
  position: fixed;
  left: 0;
  top: 71px;
  width: 100%;
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.04);
  z-index: 100;
  transition: opacity 0.2s, transform 0.15s;
}

header .right .mobile-nav.enter {
  opacity: 0;
  transform: translateY(-30px);
}

header .right .mobile-nav.enter-active {
  opacity: 1;
  transform: translateY(0);
}

header .right .mobile-nav.exit {
  opacity: 1;
  transform: translateY(0);
}

header .right .mobile-nav.exit-active {
  transform: translateY(-30px);
  opacity: 0;
}

header .right .mobile-nav ul li {
  text-align: center;
  border-bottom: 1px solid #e7e9ed;
}

header .right .mobile-nav ul li a {
  display: block;
  color: #5d6778;
  font-size: 14px;
  padding: 20px 0;
}

header .right .mobile-nav ul li a.active {
  color: #0572d4;
}

header .auth-btns {
  display: flex;
  align-items: center;
  margin-right: 12px;
}

header .auth-btns a {
  font-size: 13px;
  color: #5d6778;
  padding: 8px 12px;
}

header .auth-btns .login {
  display: none;
}

header .auth-btns .signup a {
  background: #0572d4;
  color: #fff;
  font-weight: 600;
  border-radius: 32px;
}

header .auth-btns .live a {
  color: #fff;
  background: #11a702;
  font-weight: 600;
  border-radius: 32px;
}

header .user-dropdown {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

header .user-dropdown .user-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  background: #c6c8d5;
  color: #fff;
  font-size: 12px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

header .user-dropdown ul {
  position: fixed;
  top: 71px;
  right: 12px;
  background: #fff;
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.08);
  min-width: 120px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  transition: opacity 0.2s, transform 0.15s;
}

header .user-dropdown ul.enter {
  opacity: 0;
  transform: scale(0.8);
}

header .user-dropdown ul.enter-active {
  opacity: 1;
  transform: scale(1);
}

header .user-dropdown ul.exit {
  opacity: 1;
  transform: scale(1);
}

header .user-dropdown ul.exit-active {
  opacity: 0;
  transform: scale(0.8);
}

header .user-dropdown ul li:not(:last-of-type) {
  border-bottom: 1px solid #e7e9ed;
}

header .user-dropdown ul li a {
  display: block;
  padding: 16px;
  text-align: center;
  font-size: 13px;
  font-weight: 600;
}

header .nav-trigger {
  font-size: 20px;
  padding: 8px 12px;
  cursor: pointer;
}

@media (min-width: 720px) {
  header .right .main-nav {
    display: block;
    margin-right: 16px;
  }

  header .right .main-nav ul {
    display: flex;
    align-items: center;
  }

  header .right .main-nav ul li:not(:last-of-type) {
    margin-right: 8px;
  }

  header .right .main-nav ul li a {
    display: block;
    color: #5d6778;
    font-size: 14px;
    padding: 8px 12px;
  }

  header .right .main-nav ul li a.active {
    color: #0572d4;
  }

  header .auth-btns .login {
    display: block;
  }

  header .auth-btns .login a {
    font-weight: 600;
    /* color: #0572d4; */
  }

  header .nav-trigger {
    display: none;
  }
}

/* -------------- Docs Home Page ------------- */
#docs-home-page .banner {
  text-align: center;
  padding: 48px 16px;
  background: #0572d4;
  margin-bottom: 32px;
}

#docs-home-page .banner .title {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 16px;
}

#docs-home-page .banner .tagline {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  line-height: 20px;
}

@media (min-width: 640px) {
  #docs-home-page .banner {
    padding: 80px 16px;
  }

  #docs-home-page .banner .title {
    font-size: 48px;
  }

  #docs-home-page .banner .tagline {
    font-size: 16px;
  }
}

#docs-home-page .docs-sections {
  padding: 16px;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}

#docs-home-page .docs-sections .sections .section {
  margin-bottom: 22px;
  border: 1px solid #e7e9ed;
  border-radius: 4px;
  transition: transform 0.2s ease-out;
}

#docs-home-page .docs-sections .sections .section:hover {
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.08);
  transform: scale(1.01);
}

#docs-home-page .docs-sections .sections .section a {
  display: block;
  padding: 16px;
}

#docs-home-page .docs-sections .sections .section .top {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

#docs-home-page .docs-sections .sections .section .top span {
  color: #0572d4;
  background: #eaf1f7;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  line-height: 50px;
  margin-right: 12px;
  font-size: 20px;
}

#docs-home-page .docs-sections .sections .section .top .title {
  color: #252930;
  font-size: 16px;
  font-weight: 600;
}

#docs-home-page .docs-sections .sections .section .description {
  color: #8c97a9;
  font-size: 14px;
  line-height: 21px;
}

@media (min-width: 992px) {
  #docs-home-page .docs-sections .sections {
    display: flex;
    flex-wrap: wrap;
    align-content: stretch;
  }

  #docs-home-page .docs-sections .sections .section {
    width: calc((100% / 3) - 22px);
  }

  #docs-home-page .docs-sections .sections .section:nth-child(3n + 2) {
    margin-left: 32px;
    margin-right: 32px;
  }

  #docs-home-page .docs-sections .sections .section a {
    padding: 24px;
  }
}

/* -------------- Read Docs Home Page ------------- */

.docs-heading-1 {
  font-size: 32px;
  font-weight: 700;
  color: #0572d4;
}

.docs-heading-1:not(:last-child) {
  margin-bottom: 32px;
}

.docs-heading-2 {
  color: #0572d4;
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 24px;
}

.docs-heading-3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 16px;
}

.docs-heading-4 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 16px;
}

.docs-text-1 {
  color: #808a9c;
  font-size: 15px;
  line-height: 24px;
}

.docs-gist {
  margin-bottom: 32px;
}

#read-docs-page section .docs-text-1:not(:last-child) {
  margin-bottom: 16px;
}

#read-docs-page .definitions {
  overflow-x: auto;
  margin-bottom: 24px;
}

#read-docs-page .definitions .definition {
  color: #808a9c;
  margin-bottom: 12px;
  white-space: nowrap;
}

#read-docs-page .definitions .definition .key {
  margin-right: 8px;
  font-size: 13px;
  font-weight: 600;
}

#read-docs-page .definitions .definition .value {
  font-size: 16px;
  color: #d46707;
}

.docs-table {
  overflow-x: auto;
  margin-bottom: 32px;
}

.docs-table table {
  border-collapse: collapse;
  width: 100%;
}

.docs-table table th,
.docs-table table td {
  border: 1px solid #eaf1f7;
  padding: 16px;
  text-align: left;
  font-size: 14px;
  line-height: 20px;
}

.docs-table table th {
  font-size: 13px;
  font-weight: 600;
}

.docs-table table td {
  font-size: 14px;
  color: #808a9c;
}

.docs-list li {
  color: #808a9c;
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 16px;
  position: relative;
  padding-left: 24px;
}

.docs-list li::after {
  display: block;
  content: '';
  position: absolute;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: #808a9c;
  top: 9px;
  left: 6px;
}

#read-docs-page section {
  padding: 24px 0;
  border-bottom: 1px solid #e7e9ed;
}

#read-docs-page .main-content > .wrapper {
  padding: 16px;
  max-width: 840px;
  margin: 0 auto;
}

@media (min-width: 840px) {
  #read-docs-page .main-content > .wrapper {
    padding: 32px;
  }
}

/* ------------ Transactions Page ------------- */
#transactions-page {
  padding: 32px;
}

.transactions-table {
  overflow-x: auto;
  margin-bottom: 32px;
}

.transactions-table table {
  border-collapse: collapse;
  width: 100%;
}

.transactions-table table tbody tr {
  cursor: pointer;
}

.transactions-table table tbody tr:hover {
  background: #eaf1f7;
}

.transactions-table table th,
.transactions-table table td {
  border: 1px solid #eaf1f7;
  padding: 16px;
  text-align: left;
  font-size: 14px;
  line-height: 20px;
}

.transactions-table table th {
  font-size: 13px;
  font-weight: 600;
}

.transactions-table table thead th {
  white-space: nowrap;
  background: #e7e9ed;
}

.transactions-table table td {
  font-size: 14px;
  color: #808a9c;
}

/* ---------------- Login Page ----------------- */
/* .auth-page form {
  padding: 48px 16px;
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
}

.auth-page .intro {
  background: #0572d4;
  color: #fff;
  text-align: center;
  padding: 72px 16px;
}

.auth-page h2 {
  font-size: 24px;
  font-weight: 600;
}

.auth-page .bottom {
  margin-top: 16px;
}

.auth-page .bottom p {
  font-size: 13px;
  font-weight: 600;
  text-align: center;
  color: #8c97a9;
}

.auth-page .bottom p a {
  color: #0572d4;
}

.auth-page .bottom p a:hover {
  border-bottom: 2px solid #0572d4;
}

#login-page .group.password label {
  display: block;
  position: relative;
  width: 100%;
}

#login-page .group.password label a {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 14px;
  padding: 4px;
  color: #0572d4;
  font-weight: 600;
}

#register-page .completed {
  padding: 48px 16px;
  text-align: center;
}

#register-page .completed h2 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 8px;
}

#register-page .completed p {
  font-size: 14px;
  color: #5d6778;
}

#set-password-page .completed {
  padding: 48px 16px;
  text-align: center;
}

#set-password-page .completed h2 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 8px;
}

#set-password-page .completed p {
  font-size: 14px;
  color: #5d6778;
  margin-bottom: 32px;
} */

/* ------------ Console - Users Page ------------- */
#users-page {
  padding: 32px;
}

#users-page .top {
  margin-bottom: 16px;
}

#users-page .bottom {
  margin-top: 32px;
}

/* ------------ Console - Config Page ------------- */
#config-page {
  padding: 32px;
}

#config-page section {
  margin-bottom: 32px;
}

#config-page section.msisdn ul li {
  color: #808a9c;
  margin-bottom: 4px;
  font-size: 14px;
}

#config-page .bottom {
  padding: 8px 0;
  border-top: 1px solid #e7e9ed;
  margin-top: 32px;
}

/* ------------ Console - Profile Page ------------- */
#profile-page {
  padding: 32px;
}

#profile-page .top {
  margin-bottom: 16px;
}

#profile-page .profile li {
  margin-bottom: 16px;
}

#profile-page .key {
  font-size: 13px;
  color: #808a9c;
  margin-bottom: 4px;
}

#profile-page .value {
  font-size: 16px;
}

#profile-page .bottom {
  padding: 8px 0;
  border-top: 1px solid #e7e9ed;
  margin-top: 32px;
}

#profile-page .bottom .actions a:not(:last-of-type) {
  margin-right: 16px;
}

/* ------------ Console - Edit Config Page ------------- */
#edit-config-page section {
  margin-bottom: 32px;
}

#edit-config-page section.msisdn .heading-2 {
  position: relative;
}

#edit-config-page section.msisdn .heading-2 i {
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  font-size: 14px;

  cursor: pointer;
  padding: 4px;
}

#edit-config-page section.msisdn ul li {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

#edit-config-page section.msisdn ul li .input-container {
  flex: 1;
}

#edit-config-page section.msisdn ul li .delete {
  color: #d11212;
  cursor: pointer;
  padding: 4px;
  margin-left: 8px;
}
